/* eslint-disable */
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
    },
    html: {
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#fff',
      height: '100%',
      width: '100%'
    },
    a: {
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
