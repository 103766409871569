/*eslint-disable*/
import { Navigate } from 'react-router-dom';
import React, { lazy, Suspense } from "react";
import { Loading } from './shared/utils/Loader';
import NotFound from './logged_in/pages/NotFound';
const Dashboard = lazy(() => import('src/logged_in/pages/Dashboard'));
const Project = lazy(() => import('src/logged_in/pages/Project'));
const FAQ = lazy(() => import('src/logged_in/pages/FAQ'));
const Tasks = lazy(() => import('src/logged_in/pages/Tasks'));
const Goals = lazy(() => import('src/logged_in/pages/Goals'));
const Team = lazy(() => import('src/logged_in/pages/Team'))
const Settings = lazy(() => import('src/logged_in/pages/Settings'))
const Users = lazy(() => import('src/logged_in/pages/Users'))
const TimesheetApproval = lazy(() => import('src/logged_in/pages/TimesheetApproval'))
const MyTimesheets = lazy(() => import('src/logged_in/pages/MyTimesheets'))
const DashboardLayout = lazy(() => import('src/logged_in/components/DashboardLayout'))
const LoginForm = lazy(() => import('src/logged_out/LoginForm'))



export const routes = (user) => [
  {
    path: '/user',
    element: user ? <Suspense fallback={<Loading />}><DashboardLayout /></Suspense> : <Navigate to="/login" />,
    children: [
      { path: 'dashboard', element: <Suspense fallback={<Loading />}><Dashboard /></Suspense> },
      { path: 'projects', element: <Suspense fallback={<Loading />}><Project /></Suspense> },
      { path: 'tasks', element: <Suspense fallback={<Loading />}><Tasks /></Suspense> },
      { path: 'timesheets', element: <Suspense fallback={<Loading />}><TimesheetApproval /></Suspense> },
      { path: 'my-timesheets', element: <Suspense fallback={<Loading />}><MyTimesheets /></Suspense> },
      { path: 'goals', element: <Suspense fallback={<Loading />}><Goals /></Suspense> },
      { path: 'team', element: <Suspense fallback={<Loading />}><Team /></Suspense> },
      { path: 'system-user', element: <Suspense fallback={<Loading />}><Users /></Suspense> },
      { path: 'settings', element: <Suspense fallback={<Loading />}><Settings /></Suspense> },
      { path: 'faq', element: <Suspense fallback={<Loading />}><FAQ /></Suspense> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },

  {
    path: '/',
    element: !user ? <Suspense fallback={<Loading />}><LoginForm /></Suspense> : <Navigate to="/user/dashboard" />,
    children: [
      { path: 'login', element: <LoginForm /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/', element: <Navigate to="/login" /> },
    ]
  },
];



