/* eslint-disable */
import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './App.scss'
import "src/logged_in/components/modals/styles/Modals.scss"


ReactDOM.render((
  <BrowserRouter >
    <App />
  </BrowserRouter>

),
  document.getElementById('root'));

// serviceWorker.unregister();
serviceWorker.register();

























