/* eslint-disable */
import { createMuiTheme, colors } from '@material-ui/core';

const theme = createMuiTheme({

  palette: {
    background: {
      default: '#fff',
      primary: '#1F3265',
      secondary: '#F2F2F2',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#1F3265'
    },


    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
      textsize: "0.8rem",
      fontweight: "400",
    },
    navtext: {
      fontsize: '2rem'

    }


  },
});

export default theme;
