/*eslint-disable*/
import React from "react";
import "./Loader.scss";

export function Loading() {
  return (
    <div className="full-width-loader">
      <svg>
        <circle cx="50" cy="50" r="25" stroke="#4B4D68" stroke-dasharray="62.8 188.8" stroke-width="3" fill="none" />
        <circle cx="50" cy="50" r="15" stroke="green" stroke-dasharray="47.1 141.3" stroke-width="3" fill="none" />
      </svg>
    </div>
  );
}