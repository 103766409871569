/* eslint-disable */
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './logged_in/components/GlobalStyles';
import 'src/logged_in/mixins/chartjs';
import { routes } from 'src/routes';
import theme from 'src/shared/theme';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from 'src/logged_in/components/services/firebase';
import "src/logged_in/components/modals/styles/Modals.scss";
import "src/logged_in/pages/Pages.scss";


const App = () => {
  const [user] = useAuthState(auth);
  const route = useRoutes(routes(user));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {route}
    </ThemeProvider>
  );
};
export default App;




















